import type { PropertiesDefintion } from './DataObject.PropertiesData.ts';
import type { DataObject, DataItemModel, RecordSourceOptions, DataObjectDefinitionFieldType } from 'o365-dataobject';
import { context, getOrCreateProcedure } from 'o365-modules';

import { getOrCreateDataObject } from 'o365-dataobject';

export default class PropertiesEditor {
    private _props: { config: PropertiesDefintion; row: DataItemModel };

    private _dataObject?: DataObject;
    private _columns?: ReturnType<typeof parseColumnsFromString>;
    private _lookupValues?: ReturnType<typeof parseLookupValuesFromString>;
    private _updateMainValueField: (pValue: any) => void;
    private _isLoading = false;

    get row() {
        return this._props.row;
    }

    get config() {
        return this._props.config
    }

    get dataObject() {
        return this._dataObject;
    }

    get columns() {
        return this._columns;
    }

    get lookupValues() {
        return this._lookupValues;
    }

    get isMultiselect() {
        return this.config?.inputEditor?.Multiselect ?? false;
    }

    get contextField() {
        return this.config?.inputEditor?.RestrictToContext
            ? 'OrgUnitIdPath'
            : undefined;
    }

    get editor() {
        if (this.config.inputEditor) {
            switch (this.config.inputEditor.Type) {
                case 'TimePicker':
                    return 'time';
                case 'DatePicker':
                    return this.config.dataType === 'datetime' ? 'datetime' : 'date';
                case 'OrgUnit':
                    return 'orgunit';
                case 'Object':
                    return 'object';
                case 'Lookup':
                    return this.config.hasLookupValues && this.config.inputEditor.ViewName == null
                        ? 'local_lookup'
                        : 'lookup';
                default:
                    return this.config.hasLookupValues ? 'local_lookup' : this._getEditorFromDataType(this.config.dataType);
            }
        } else {
            return this._getEditorFromDataType(this.config.dataType);
        }
    }

    get isLoading() { return this._isLoading; }

    get whereClause() { return this.config?.whereClause; }


    constructor(pProps: { config: PropertiesDefintion; row: DataItemModel }, pUpdateMainValueField: (pValue: any) => void) {
        this._props = pProps;

        if (this.config.inputEditor) {
            this._dataObject = getDataObjectForInputEdior(this.config.inputEditor, this.config);
            if (this.config.inputEditor.Columns) {
                this._columns = parseColumnsFromString(this.config.inputEditor.Columns)
            }
        }
        this._updateMainValueField = pUpdateMainValueField;
    }

    async initialize() {
        if (this.config.hasLookupValues) {
            this._isLoading = true;
            this._lookupValues = [];
            this._lookupValues.splice(0, 0, ...(await getLookupValues(this.config.name)));
            if(this.isMultiselect){
                this._lookupValues.forEach(item=>{
                    this.isSelected(item,this.row);
                })
            }
            this._isLoading = false;
        }
    }

    lookupBind(pSel: DataItemModel) {
        if (this.editor === 'orgunit') {
            this._updateMainValueField(pSel.OrgUnit);
            this.updateValueFields([
                { field: 'Value', value: pSel.OrgUnit },
                { field: 'IntValue', value: pSel.ID },
            ]);
        } else if (this.editor === 'object') {
            this._updateMainValueField(pSel.Name);
            const fields = [
                { field: 'Value', value: pSel.Name },
                { field: 'IntValue', value: pSel.ID },
            ];
            if (this._dataObject?.propertiesData?.propertiesDataObject?.fields['IsObjectConnection']) {

            } fields.push({ field: 'IsObjectConnection', value: 1 });
            this.updateValueFields(fields as any);
        } else {
            const valueField = this.config.inputEditor?.ValueMember;
            const displayField = this.config.inputEditor?.DisplayMember;
            if (valueField == null || displayField == null) { return; }
            const displayValue = this.isMultiselect
              //  ? (pSel as any as DataItemModel[]).map(sel => sel[displayField]).join(', ')
                ? JSON.stringify((pSel as any as DataItemModel[]).map(sel => sel[displayField]))
                : pSel[displayField];
            const value = this.isMultiselect
                //? (pSel as any as DataItemModel[]).map(sel => sel[valueField]).join(', ')
                ? (pSel as any as DataItemModel[]).map(sel => sel[valueField]).join(', ')
                : pSel[valueField];
            this._updateMainValueField(displayValue);
            if (!this.isMultiselect) {
                this.updateValueFields([
                    { field: 'Value', value: displayValue, },
                    { field: 'IntValue', value: value, }
                ]);
            }
        }
    }

    localLookupBind(pSel: { Value: any }) {
        this._updateMainValueField(pSel.Value);
    }

    isSelected(lkpItem:any, row:any){
        if(!this.config?.inputEditor) return;
        if(!this.isMultiselect) return;
        if(row && !row.Value) return;
        let vValue = row.Value;
        const vValueMember = this.config.inputEditor.ValueMember;;
        try{
            vValue = JSON.parse(vValue);
        }catch{
            vValue = vValue.split(",");
        }
        vValue = vValue.map(x=>x?.toString().trim());

        lkpItem.isSelected = vValue.indexOf(lkpItem[vValueMember].toString()) > -1
       
    }

    async updateValueFields(pValues: AdditionalFieldsUpdate[]) {
        const { nextTick } = await import('vue');
        await nextTick();
        pValues.forEach(binding => {
            this.row[binding.field] = binding.value;
        });
    }

    private _getEditorFromDataType(pType: string) {
        switch (pType) {
            case 'bool':
                return 'bit';
            case 'date':
                return 'date';
            case 'datetime':
                return 'datetime';
            case 'number':
                return 'number';
            case 'decimal':
                return 'decimal';
            default:
                return this.config.hasLookupValues ? 'local_lookup' : 'text';
        }
    }
};

function getDataObjectForInputEdior(pConfig: NonNullable<PropertiesDefintion['inputEditor']>, pPropertyConfig: PropertiesDefintion) {
    if (pConfig.ViewName == null || pConfig.Columns == null) { return undefined; }

    const id = `o_dsInputEditor_${pConfig.Name}`;

    const columns = parseColumnsFromString(pConfig.Columns);

    const sortColumn = pConfig.SortOrder?.split(':')[0];
    let sortDirection = pConfig.SortOrder?.split(':')[1];
    if (sortColumn) {
        if (sortDirection) {
            sortDirection = sortDirection.toLowerCase();
        } else {
            sortDirection = 'asc';
        }
        const existingColumnIndex = columns.findIndex(x => x.name === sortColumn);
        if (existingColumnIndex === -1) {
            columns.push({ name: sortColumn, sortOrder: 1, sortDirection: (sortDirection as 'asc' | 'desc') });
        } else {
            columns[existingColumnIndex].sortOrder = 1;
            columns[existingColumnIndex].sortDirection = sortDirection as 'asc' | 'desc';
        }
    }

    const dataObject = getOrCreateDataObject({
        id: id,
        appId: 'site',
        viewName: pConfig.ViewName,
        fields: columns,
        whereClause: pPropertyConfig?.whereClause
    });

    return dataObject;
}

function parseColumnsFromString(pColumns: string): NonNullable<RecordSourceOptions['fields']> {
    return pColumns.split(',').map(column => {
        const values = column.split(':');
        return ({
            name: values[0],
            size: values[1],
            type: values[2] as DataObjectDefinitionFieldType['type']
        });
    });
}

function parseLookupValuesFromString(pLookupValues: string): { Value: any; SortOrder: number }[] {
    return JSON.parse(pLookupValues);
}

const propertyLookupValuesCache = new Map<string, { Value: any, SortOrder: number }[]>();

/** Get values for property from stbv_System_PropertiesValues */
async function getLookupValues(pProperty: string): Promise<{ Value: any; SortOrder: number }[]> {
    const key = `${pProperty}-${context.id||'nocontext'}`
    if (propertyLookupValuesCache.has(key)) {
        return propertyLookupValuesCache.get(key)!;
    }

    var procGetPropValues = getOrCreateProcedure({
        id: 'o_procGetPropValues',
        procedureName: 'sstp_System_GetPropertiesValuesOrgUnits'
    });

    const response = await procGetPropValues.execute({
        PropertyName: pProperty,
        Context_ID: context.id
    })
    
    const sortedValues = response.Table.sort((a: { Value: any, SortOrder: number }, b: { Value: any, SortOrder: number }) => a.SortOrder - b.SortOrder);

    propertyLookupValuesCache.set(pProperty, sortedValues);
    return sortedValues;
}

type AdditionalFieldsUpdate = {
    field: PropertiesDefintion['valueField'],
    value: any
};
